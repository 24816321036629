import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import buchetulImage from "../images/buchetul.jpg"

const BuchetulPage = () => (
  <Layout>
    <SEO title="Buchetul de mireasă" />
    <div className="drawer article-content">
      <h1 className="article-title">Buchetul de mireasă</h1>
      <p>
        Bujori, lalele, liliac, hortensie? Probabil fiecare mireasa porneste de
        la gandul de a-si vedea floarea preferata in buchetul de mireasa sau de
        a-si gasi flori din culorile preferate.
      </p>
      <p>
        Daca florile din aranjamente intrau la categoria detalii care fac
        diferența, cu siguranță buchetul miresei reprezintă unul dintre cele mai
        importante elemente. Poate pentru și că este singurul accesoriu al
        miresei sau dacă nu singurul, cu siguranță este cel mai vizibil (și
        poate cel mai apreciat).
      </p>
      <img src={buchetulImage} />

      <p>
        Cum să alegi buchetul potrivit? Poți lua în calcul următoarele aspecte:
      </p>
      <section>
        <h3 className="paragh-title">Floarea ta preferată</h3>
        <p>
          Cu siguranță vei găsi o metodă să incluzi și floarea preferată în
          buchet, în cazul în care nu poți avea un buchet doar din floarea
          respectivă.
        </p>
      </section>
      <section>
        <h3 className="paragh-title">Sezonul în care are loc nunta</h3>
        <p>
          Ideal ar fi să incluzi în buchetul tău flori din sezonul respectiv
          pentru că sunt mai ușor de găsit (poate nu vei reuși să găsești liliac
          iarna), probabil sunt și mai rezistente fiind în sezonul lor de
          creștere, florile cumpărate în extra-sezon au prețuri mult mai mari,
          se potrivesc mai bine în “peisaj”, de exemplu un buchet
          burgundy/portocaliu pentru o nuntă de toamnă.
        </p>
      </section>
      <section>
        <h3 className="paragh-title">Tematica sau culorile nunții</h3>{" "}
        <p>
          Este clar că buchetul tău trebuie să se potrivească cu restul
          poveștii. Florile de câmp se potrivesc la nunțile rustice, iar dacă
          culorile nunții sunt roșu și auriu, un buchet roz nu este opțiunea
          ideală.
        </p>
      </section>
      <section>
        <h3 className="paragh-title">Rochia ta</h3>{" "}
        <p>
          Sigur că îți dorești ca totul să fie la superlativ, trebuie însă să ai
          grijă să nu exagerezi. O rochie de prințesă, cu crinolină sau cu
          aplicații nu se complează cu un buchet extravagant, ci cu un buchet
          mai simplu. O rochie lejeră nu se completează cu un buchet din
          trandafiri, crini, perfect rotund, ci mai degrabă cu un buchet în care
          florile sunt așezate mai neglijent.
        </p>
      </section>
      <section>
        <p>
          Poți alege buchete rotunde, cascadă, buchete voluminoase, buchete din
          câteva fire. Cea mai bună variantă este să cauți poze cu un buchet
          apropiat de cel pe care ți-l dorești și să mai adaugi/înlocuiești
          anumite flori, astfel va fi mult mai ușor să îți dai seama cum va
          arăta, decât să ai o supriză în ziua nunții, că buchetul nu arăta cum
          ți-l imaginai.
        </p>
      </section>
      <div className="nav-section">
        <Link to="/cununiacivila">◀ Cununia Civilă </Link>
        <Link to="/pastrareaBuchetului">
        ▶ Cum păstrăm buchetul de mireasă?
        </Link>
      </div>
    </div>
  </Layout>
)

export default BuchetulPage
